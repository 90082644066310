export default {
  en: {
    'user.users': 'Users',
    'user.user': 'User',
    'user.confirmDeleteUser': 'Are you sure you want to delete this user?',
    'user.createNewUser': 'Create New User',
    'user.editUser': 'Edit User',
    'user.email': 'Email',
    'user.createdAt': 'Created At',
    'user.lastLogin': 'Last login',
    'user.accessGroups': 'Access Groups',
    'user.facilities': 'Facilities',
    'user.resendTempPassword': 'Send a new temporary password',
    'user.userTempPasswordResent': 'The temporary password has been resent',
    'user.resetMfa': 'Reset Two-Step Verification',
    'user.disbleMfa': 'Disable Two-Step Verification',
    'user.mfaDisabled': 'Two-step verification has been disabled',
    'user.mfaResetted': 'Two-step verification has been reset',
    'user.confirmRemoveAccess':
      'By removing access groups, you may lose some functionality. Are you sure you want to proceed?',
    'user.confirmResetMfa':
      'Resetting the two-step verification should only be done if the user no longer has access to his verification app. After the reset, the user will receive a temporary password by email.',
    'user.confirmDisableMfa':
      'Disabling the two-step verification should only be done if the user no longer has access to his verification app. Once disabled, the user will be able to configure it again.',
    'userPreferences.emailNotificationsQuestion': 'Receive email notifications',
    'userPreferences.SMSNotificationsQuestion': 'Receive text message notifications',
    'userPreferences.SMSNotificationsQuestion.noPhoneNumber':
      'You will not receive any SMS notifications because no phone number is associated with your account.',

    //Two-Factor Authentication
    'mfa.description':
      'With two-step verification, you can add an extra layer of security to your account in case your password is stolen.',
    'mfa.followSteps': 'Follow the steps below to activate two-step verification',
    'mfa.downloadApp':
      "Download a two-step verification app if you don't have one. You can download the free Google Authenticator app to proceed with two-step verification.",
    'mfa.androidDevices': 'Android devices',
    'mfa.iOSDevices': 'Apple devices (iPhone, iPad)',
    'mfa.googleAuthenticatorAndroid': 'Google Authenticator for Android',
    'mfa.googleAuthenticatorIOS': 'Google Authenticator on the App Store',
    'mfa.scanQRCode': 'Scan this QR code with your verification app',
    'mfa.enterCode': 'Enter the code generated by your verification app',
    'mfa.confirmDeactivate': 'Do you really want to deactivate two-step verification?',
    'mfa.active': 'Active',
    'mfa.inactive': 'Inactive',
    'mfa.activate': 'Activate',
    'mfa.deactivate': 'Deactivate',
    'password.oldPassword': 'Old password',
  },
  fr: {
    'user.users': 'Utilisateurs',
    'user.user': 'Utilisateur',
    'user.confirmDeleteUser': 'Voulez-vous supprimer cet utilisateur?',
    'user.createNewUser': 'Créer un nouvel utilisateur',
    'user.editUser': "Modifier l'utilisateur",
    'user.email': 'Courriel',
    'user.createdAt': 'Date de création',
    'user.lastLogin': 'Dernière connexion',
    'user.accessGroups': "Groupes d'accès",
    'user.facilities': 'Installations',
    'user.resendTempPassword': 'Envoyer un nouveau mot de passe temporaire',
    'user.userTempPasswordResent': 'Le mot de passe temporaire a été renvoyé',
    'user.resetMfa': 'Réinitialiser vérif. en deux étapes',
    'user.disableMfa': 'Désactiver vérif. en deux étapes',
    'user.mfaDisabled': 'La vérification en deux étapes a été désactivée',
    'user.mfaResetted': 'La vérification en deux étapes a été réinitialisée',
    'user.confirmRemoveAccess':
      "En retirant des groupes d'accès vous risquez de perdre certaines fonctionnalités. Désirez-vous vraiment enregistrer?",
    'user.confirmResetMfa':
      "La réinitialisation de la vérification en deux étapes ne devrait être faite que si l'utilisateur n'a plus accès à son application de vérification. Suite à la réinitialisation, l'utilisateur recevra un mot de passe temporaire par courriel.",
    'user.confirmDisableMfa':
      "La désactivation de la vérification en deux étapes ne devrait être faite que si l'utilisateur n'a plus accès à son application de vérification. Suite à la désactivation l'utilisateur pourra la configurer de nouveau.",
    'userPreferences.emailNotificationsQuestion': 'Recevoir les notifications par courriel',
    'userPreferences.SMSNotificationsQuestion': 'Recevoir les notifications par messagerie texte',
    'userPreferences.SMSNotificationsQuestion.noPhoneNumber':
      "Vous ne recevrez aucune notifications par SMS car aucun numéro de téléphone n'est associé à votre compte.",

    //Two-Factor Authentication
    'mfa.description':
      "La vérification en deux étapes vous permet d'ajouter un niveau de sécurité supplémentaire afin de protéger votre compte en cas de vol de votre mot de passe.",
    'mfa.followSteps': 'Veuillez suivre la procédure suivante pour activer la vérification en deux étapes',
    'mfa.downloadApp':
      "Téléchargez une application de vérification en deux étapes si vous n'en avez pas une. Vous pouvez télécharger l'application gratuite Google Authenticator pour effectuer la vérification en deux étapes.",
    'mfa.androidDevices': 'Appareils Android',
    'mfa.iOSDevices': 'Appareils Apple (iPhone, iPad)',
    'mfa.googleAuthenticatorAndroid': 'Google Authenticator pour Android',
    'mfa.googleAuthenticatorIOS': "Google Authenticator dans l'App Store",
    'mfa.scanQRCode': 'Scannez ce code QR avec votre application de vérification',
    'mfa.enterCode': "Entrez le code de généré par l'application de vérification",
    'mfa.confirmDeactivate': 'Voulez-vous vraiment désactiver la vérification en deux étapes?',
    'mfa.active': 'Activée',
    'mfa.inactive': 'Désactivée',
    'mfa.activate': 'Activer',
    'mfa.deactivate': 'Désactiver',
    'password.oldPassword': 'Ancien mot de passe',
  },
};
