import api from './baseApiService';

class UserService {
  async getUsers() {
    return await api.get('users');
  }

  async getUserById(id) {
    return await api.get(`user/${id}`);
  }

  async createUser(data) {
    return await api.post('user/', data);
  }

  async updateUser(id, data) {
    return await api.put(`user/${id}/`, data);
  }

  async getCurrentUserPermissions() {
    return await api.get('currentuser/permissions');
  }

  async getAllFacilities() {
    return await api.get('facilities');
  }

  async getOrganizations() {
    return await api.get('organizations');
  }

  async getUserPreferences() {
    return await api.get('userpreferences');
  }

  async updateUserPreferences(data) {
    return await api.put('userpreferences/', data);
  }

  async resendEmailTempPassword(userId) {
    return api.put(`user/${userId}/resendtemppassword/`);
  }

  async resetMfa(userId) {
    return api.put(`user/${userId}/resetmfa/`);
  }

  async disableMfa(userId) {
    return api.put(`user/${userId}/disablemfa/`);
  }

  async getUserToken() {
    return api.post('usertoken');
  }

  async getUserGridConfiguration(gridName) {
    return await api.get(`usergridconfiguration/${gridName}`);
  }

  async updateUserGridConfiguration(gridName, data) {
    return await api.put(`usergridconfiguration/${gridName}/`, data);
  }
}

export default new UserService();
